import React from 'react';

import { moduleLoader } from '@deriv/shared';
import { observer } from '@deriv/stores';

const AppStore = React.lazy(() =>
    moduleLoader(() => {
        // eslint-disable-next-line import/no-unresolved
        return import(/* webpackChunkName: "appstore" */ '@deriv/appstore');
    })
);

const RootComponent = observer(props => {
    return <AppStore {...props} />;
});

export default RootComponent;
